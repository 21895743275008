<template>
  <div class="tw-overflow-visible">
    <Card
      :has-body-padding-small="true"
    >
      <template #body>
        <p
          v-if="post.next_id"
          :class="bannerClass"
        >
          <span class="heading-color tw-mr-1">Heads up, there may be fresher snow!</span>
          <NuxtLink
            class="tw-underline link-color-brand"
            :to="newestPostUrl"
          >
            {{ `Read the latest ${name} Daily Snow` }}
          </NuxtLink>
        </p>
        <PostHeader :post="post" />
        <PostTitle
          :post="post"
          :post-url="postUrl"
        />
        <PostActions
          v-if="!inAppView"
          :daily-read="dailyRead"
        />
        <PostContent
          class="tw-mt-6"
          :is-daily-snow="true"
          :post="post"
        />
      </template>
    </Card>
    <UpgradeToAllAccessCard
      v-if="!hasContent"
      :class="upgradeToAllAccessCardClass"
      :header-text="headerText"
      :is-guest="isGuest"
      :in-app-view="inAppView"
      :return-to="returnTo"
      :show-benefits="true"
      source="dailysnow_post"
      :upgrade-reasons="upgradeReasons"
    />
  </div>
</template>

<script>
import { mapState } from 'pinia';
import AppViewMixin from '@@/utils/AppViewMixin';
import { addInAppViewToPath } from '@@/utils/CommonUtils';
import { useMetaStore } from '@@/stores/Meta';

/**
 * The <DailySnowPost> component is built as a standalone component with no external dependencies
 * so that it can be demonstrated in StoryBook.
 *
 * Most of these props could be obtained from the Vuex stores by the the component itself, but then
 * it wouldn't be able to be demonstrated in Storybook because it would make API requests to fetch
 * external data. That's the reason why isGuest, and upgradeReasons are specified as
 * props. It's also the reason why the <UpgradeToAllAccessCard> is rendered above! The
 * <UpgradeToAllAccess> component makes a GET /meta/pricing request which won't work when demo'd in
 * StoryBook.
 */
export default {
  name: 'DailySnowPost',

  mixins: [AppViewMixin],

  props: {
    isGuest: {
      type: Boolean,
      default: false,
    },
    dailyRead: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapState(useMetaStore, { upgradeReasons: (state) => state?.pricing?.upgrade_reasons || [] }),

    bannerClass() {
      return [
        'tw-mb-5 tw-p-4 tw-rounded-lg tw-border-1',
        'tw-text-base tw-font-medium tw-text-center',
        this.$style.banner,
      ];
    },

    post() {
      return this.dailyRead.post;
    },

    name() {
      return this.dailyRead.name;
    },

    slug() {
      return this.dailyRead.slug;
    },

    id() {
      return this.dailyRead.id;
    },

    hasContent() {
      return this.post.content;
    },

    headerText() {
      return 'To read the rest of this Daily Snow, unlimited others, and enjoy 15+ other features, Upgrade to All-Access.';
    },

    returnTo() {
      return this.post?.share_url;
    },

    postUrl() {
      return addInAppViewToPath(`/dailysnow/${this.slug}/post/${this.post?.id}`, this.inAppView);
    },

    newestPostUrl() {
      return addInAppViewToPath(`/dailysnow/${this.slug}`, this.inAppView);
    },

    upgradeToAllAccessCardClass() {
      return `tw-relative tw--mt-8 ${this.$style.upgradeToAllAccessCard}`;
    },
  },
};
</script>

<style module>
.banner {
  background-color: rgb(var(--light-blue-rgb) / 25%);
  border-color: var(--saturated-blue-50);
}

.upgradeToAllAccessCard {
  box-shadow: 0 0 5rem var(--box-shadow-color-darker);
  margin-left: -2.5%;
  width: 105%;
}
</style>
